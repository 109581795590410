import { global_state } from "./GlobalContextState.js";
import { createContext, useState } from "react";

export const GlobalContext = createContext({
	state: {},
	updateState: () => { }
});

export function GlobalContextProvider({ children }) {
	let [state, setState] = useState(global_state);

	function updateState() {
		let new_state = { ...state };
		setState(new_state);
		console.log(new_state);
	}

	return (
		<GlobalContext.Provider value={{ state, updateState }}>
			{children}
		</GlobalContext.Provider>
	);
}