class _EventBus {

	constructor() {
		this.listeners = [];
	}

	emit(event_name, parameter) {
		console.log("emitting ", event_name, parameter);
		for (let handler of this.listeners) {
			if (handler.event_name == event_name) {
				handler.listener(parameter);
			}
		}
	}

	on(event_name, listener) {
		let handler = { event_name, listener };
		this.listeners.push(handler);
	}

	off(event_name, listener) {
		let handler = this.listeners.find(item => item.event_name == event_name && item.listener == listener);
		if (handler) {
			this.listeners.splice(this.listeners.indexOf(handler), 1);
		}
	}

	waitUntil(event_name) {
		return new Promise(resolve => {
			let handler = (parameter)=>{
				resolve(parameter);
				this.off(event_name, handler);
			}

			this.on(event_name, handler);
		});
	}
}
const EventBus = new _EventBus();
export default EventBus;