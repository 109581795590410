import CreateOrJoin from "./components/Views/CreateOrJoin/CreateOrJoin";
import Lobby from "./components/Views/Lobby/Lobby";
import Board from "./components/Views/Board/Board";
import { useContext, useEffect } from "react";

import "./App.css";
import EventBus from "./components/EventBus";

import global from "./components/Global";
import { GlobalContext } from "./components/Context/Global/GlobalContext";

function App() {

	let ws_url = "wss://intruder.omagerio.it:8001/intruder";
	// let ws_url = "ws://localhost:8001/intruder";

	let context = useContext(GlobalContext);

	async function show_join_or_create() {
		let storage_player_id = localStorage.getItem("player_id");
		if (storage_player_id != null) {
			context.state.player_id = storage_player_id;
		} else {
			context.state.player_id = global.uuid();
			localStorage.setItem("player_id", context.state.player_id);
		}

		global.ws.send(JSON.stringify({
			label: "player_id",
			player_id: context.state.player_id
		}));

		while (1) {
			let message = await EventBus.waitUntil("ws_message_received");
			if (message.label == "player_id_confirm") {
				break;
			}
		}

		context.state.current_view = "main";
		context.updateState();

		let query_string = new URLSearchParams(document.location.search);
		let qs_number = query_string.get("n");

		let storage_game_id = localStorage.getItem("game_id");
		if (qs_number != null) {
			storage_game_id = qs_number;
		}

		if (storage_game_id != null) {
			global.ws.send(JSON.stringify({
				label: "join_game",
				game_number: storage_game_id
			}));
		}
	}

	function show_lobby() {
		context.state.current_view = "lobby";
		context.updateState();
	}

	function show_board() {
		context.state.current_view = "board";
		context.updateState();
	}

	function on_ws_message(message) {
		console.log(message);
		EventBus.emit("ws_message_received", JSON.parse(message.data));
	}

	useEffect(
		() => {
			global.ws = new WebSocket(ws_url);
			global.ws.addEventListener("open", show_join_or_create);
			global.ws.addEventListener("close", () => {
				/*alert("lost connection to server");*/
				document.location.reload();
			});
			global.ws.addEventListener("message", on_ws_message);
			EventBus.on("intent_show_lobby", show_lobby);
			EventBus.on("intent_show_board", show_board);

			try {
				let wakeLock = navigator.wakeLock.request("screen");
			} catch (err) {
				console.error(err);
			}

			return () => {
				global.ws.removeEventListener("open", show_join_or_create);
				global.ws.removeEventListener("message", on_ws_message);
				EventBus.off("intent_show_lobby", show_lobby);
				EventBus.off("intent_show_board", show_board);
			};

		}, []
	);

	return (
		<div className="App">

			{
				context.state.current_view == "main" ? (
					<CreateOrJoin />
				) : null
			}

			{
				context.state.current_view == "lobby" ? (
					<Lobby />
				) : null
			}

			{
				context.state.current_view == "board" ? (
					<Board />
				) : null
			}
		</div>
	);
}

export default App;