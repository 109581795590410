const global = {};

global.clone = (object)=>{
    return {...object};
};

global.uuid = function(){
    return Date.now() + "_" + Math.floor(Math.random()*10000000);
}

export default global;