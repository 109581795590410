import { useContext, useEffect, useState } from "react";
import "./Lobby.css";
import Button from '@mui/joy/Button';

import global from "../../Global";
import EventBus from "../../EventBus";
import { GlobalContext } from "../../Context/Global/GlobalContext";

var QRCode = require('qrcode');

function Lobby() {

	let context = useContext(GlobalContext);
	let [state, setState] = useState({
		qrcode_dataurl: ""
	});

	function on_ws_message_received(message) {
		if (message.label == "players_count_updated") {
			context.state.game = message.game;
			context.updateState();
		}

		if (message.label == "game_started") {
			context.state.game = message.game;
			context.state.current_view = "board";
			context.updateState();
		}
	}

	function startClick() {
		global.ws.send(JSON.stringify({
			label: "start_game"
		}));
	}

	useEffect(() => {
		EventBus.on("ws_message_received", on_ws_message_received);
		QRCode.toDataURL("https://intruder.omagerio.it?n=" + context.state.game.number).then(r => {
			setState({
				qrcode_dataurl: r
			});
		});

		return () => {
			EventBus.off("ws_message_received", on_ws_message_received);
		};
	}, []);

	return (
		<div className="Lobby">
			Numero partita:
			<div className="gameNumberInput">{context.state.game.number}</div>


			{context.state.game.host == context.state.player_id ? (
				<>
					Giocatori collegati:
					<div>{context.state.game.players.length}</div>
					<div>Attendi che tutti i giocatori si siano collegati, poi premi su avvia</div>
					<Button onClick={startClick}>Avvia</Button>

					<img style={{imageRendering: "pixelated"}} src={state.qrcode_dataurl} />
				</>
			) : (
				<div>Attendi che l'organizzatore avvii la partita</div>
			)
			}
		</div>
	);
}

export default Lobby;