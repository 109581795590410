import { useContext, useEffect } from "react";
import EventBus from "../../EventBus";
import global from "../../Global";
import "./CreateOrJoin.css";
import { GlobalContext } from "../../Context/Global/GlobalContext";

function CreateOrJoin(props) {

	let context = useContext(GlobalContext);

	async function creaClick() {
		global.ws.send(JSON.stringify({
			label: "create_game"
		}));
	}

	function uniscitiClick() {
		let number = prompt("Inserisci il numero della partita");
		localStorage.setItem("game_id", number);
		global.ws.send(JSON.stringify({
			label: "join_game",
			game_number: number
		}));
	}

	function on_ws_message_received(message) {
		if (message.label == "game_created") {
			context.state.game = message.game;
			context.state.current_view = "lobby";
			context.updateState();
		}

		if (message.label == "game_joined") {
			context.state.game = message.game;
			if (message.game.started == false) {
				context.state.current_view = "lobby";
			} else {
				context.state.current_view = "board";
			}
			context.updateState();
		}
	}

	useEffect(() => {
		EventBus.on("ws_message_received", on_ws_message_received);
		return () => {
			EventBus.off("ws_message_received", on_ws_message_received);
		};
	});

	return (
		<div className="CreateOrJoin">
			<div className="button" onClick={creaClick}>Crea</div>
			<div className="button" onClick={uniscitiClick}>Unisciti</div>
		</div>
	);
}

export default CreateOrJoin;