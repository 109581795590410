import { useContext, useEffect } from "react";
import "./Board.css";

import global from "../../Global";
import EventBus from "../../EventBus";
import { GlobalContext, GlobalContextProvider } from "../../Context/Global/GlobalContext";

function Board() {

	let context = useContext(GlobalContext);

	function on_ws_message_received(message) {
		if (message.label == "players_count_updated") {
			context.state.game = message.game;
			context.updateState();
		}

		if (message.label == "game_started") {
			context.state.game = message.game;
			context.state.current_view = "board";
			context.updateState();

		}

		if (message.label == "draw_cards_success") {
			context.state.game = message.game;
			context.updateState();
		}
	}

	function drawCardsClick() {
		global.ws.send(JSON.stringify({
			label: "draw_cards"
		}));

		let el = document.querySelector("#alert");
		el.style.animation = 'none';
		// el.offsetHeight;
		el.style.animation = null;
	}

	useEffect(() => {
		console.dir(context.state.game);
		EventBus.on("ws_message_received", on_ws_message_received);

		return () => {
			EventBus.off("ws_message_received", on_ws_message_received);
		};
	}, []);

	return (
		<div className="Lobby">
			{context.state.game.intruder_id == context.state.player_id ? (
				<div className="gameNumberInput-2">Sei l'intruso!</div>
			) : (
				<>
					La tua parola è:
					<div className="gameNumberInput">{context.state.game.word}</div>
				</>
			)}

			{context.state.game.host == context.state.player_id ? (
				<>
					<div className="button" onClick={drawCardsClick}>Distribuisci nuove carte</div>
					<div id="alert" className="alert" >Carte distribuite!</div>
				</>
			) : null}
		</div>
	);
}

export default Board;